import { I18nProvider } from '@react-aria/i18n'
// import { cssBundleHref } from '@remix-run/css-bundle'
import type { LoaderFunctionArgs } from '@remix-run/cloudflare'
import { json } from '@remix-run/cloudflare'
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLoaderData,
  useLocation,
  useMatches,
  useRouteError,
} from '@remix-run/react'

import { useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import { getToast } from 'remix-toast'

import { AntdProvider } from './lib/antd-provider'

import type { PublicConfigType } from './constants/config'

import NiceModal from '@ebay/nice-modal-react'
import { ClientSentry } from './lib/client-sentry'

import reactToastifyCssHref from 'react-toastify/dist/ReactToastify.css?url'
import appCssHref from './styles/app.css?url'
import fontsCssHref from './styles/fonts.css?url'
// import './styles/tailwind.css'
import tailwindCssHref from './styles/tailwind.css?url'

export const links = () => [
  { rel: 'stylesheet', href: fontsCssHref },
  { rel: 'stylesheet', href: tailwindCssHref },
  { rel: 'stylesheet', href: appCssHref },
  { rel: 'stylesheet', href: reactToastifyCssHref },
  {
    rel: 'stylesheet',
    href: `/assets/antd-${import.meta.env.COMMIT_SHA.slice(0, 8)}.css`,
  },
]

// export const links: LinksFunction = () => [
//   ...(cssBundleHref ? [{ rel: 'stylesheet', href: cssBundleHref }] : []),
// ]

export const loader = async ({ request, context }: LoaderFunctionArgs) => {
  const { toast, headers } = await getToast(request)

  // const user = await context.authenticator.isAuthenticated(request)
  const user = {
    profile: {
      displayName: 'Test User',
    },
  }

  const appConfig: PublicConfigType = {
    COMMIT_SHA: import.meta.env.COMMIT_SHA,
    SENTRY_DSN: context.env.SENTRY_DSN,
  }

  return json(
    {
      toast,
      user: {
        displayName: user?.profile?.displayName ?? '',
      },
      appConfig,
      requestId: context.requestId,
    },
    { headers },
  )
}

export type RootLoaderData = typeof loader

export default function App() {
  const {
    toast: remixToast,
    user,
    appConfig,
    requestId,
  } = useLoaderData<typeof loader>()
  useEffect(() => {
    if (remixToast) {
      toast(remixToast.message, { type: remixToast.type })
    }
  }, [remixToast])

  if (typeof window !== 'undefined') {
    window.__APP_PUBLIC_CONFIG = appConfig
  }

  useEffect(() => {
    // https://docs.sentry.io/platforms/javascript/guides/remix/
    ClientSentry.init({
      enabled: import.meta.env.MODE != 'development',
      dsn: appConfig.SENTRY_DSN,
      release: appConfig.COMMIT_SHA,
      integrations: [
        ClientSentry.browserTracingIntegration({
          useEffect,
          useLocation,
          useMatches,
        }),
        // Replay is only available in the client
        // ClientSentry.replayIntegration(),
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    })
    const setryScope = ClientSentry.getCurrentScope()
    setryScope?.setTag('transaction_id', requestId)
    setryScope?.setTag('requestId', requestId)
  }, [appConfig.SENTRY_DSN, appConfig.COMMIT_SHA, requestId])

  return (
    <I18nProvider locale="tr-tr">
      <html lang="tr" data-theme="light">
        <head>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />

          <Meta />
          <Links />
          {/* <link rel="icon" href="/favicon.svg" /> */}
          {/* <link rel="manifest" href="manifest.json" /> */}
        </head>
        <body>
          <NiceModal.Provider>
            <AntdProvider>
              <Outlet />
            </AntdProvider>
          </NiceModal.Provider>

          <ScrollRestoration />
          <Scripts />

          {import.meta.env.MODE == 'development' && (
            <script src={'/assets/hydration-error-overlay.js'} />
          )}
          <ToastContainer position="top-right" autoClose={5000} theme="light" />
        </body>
      </html>
    </I18nProvider>
  )
}

// https://github.com/remix-run/remix/blob/main/packages/remix-react/errorBoundaries.tsx -> RemixRootDefaultErrorBoundary
export function ErrorBoundary() {
  const error = useRouteError()

  console.error('error', error)

  if (isRouteErrorResponse(error)) {
    return (
      <BoundaryShell title="Unhandled Thrown Response!">
        <h1 style={{ fontFamily: 'system-ui, sans-serif', padding: '2rem' }}>
          {error.status} {error.statusText}
        </h1>
      </BoundaryShell>
    )
  }

  let errorInstance: Error
  if (error instanceof Error) {
    errorInstance = error
  } else {
    const errorString =
      error == null
        ? 'Unknown Error'
        : typeof error === 'object' && 'toString' in error
          ? error.toString()
          : JSON.stringify(error)
    errorInstance = new Error(errorString)
  }

  if (typeof global != 'undefined') {
    // ssrFixErrorStacktrace(errorInstance)
  }

  console.error(errorInstance)

  return (
    <BoundaryShell title="Application Error!">
      <main style={{ fontFamily: 'system-ui, sans-serif', padding: '2rem' }}>
        <h1 style={{ fontSize: '24px' }}>Application Error</h1>
        <pre
          style={{
            padding: '2rem',
            background: 'hsla(10, 50%, 50%, 0.1)',
            color: 'red',
            overflow: 'auto',
          }}
        >
          {errorInstance.stack}
        </pre>
      </main>
    </BoundaryShell>
  )
}

function BoundaryShell({
  title,
  children,
}: {
  title: string
  children: React.ReactNode
}) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,viewport-fit=cover"
        />
        <title>{title}</title>
      </head>
      <body>
        {children}
        <script
          dangerouslySetInnerHTML={{
            __html: `
              console.log(
                "💿 Hey developer 👋. You can provide a way better UX than this when your app throws errors. Check out https://remix.run/guides/errors for more information."
              );
            `,
          }}
        />
      </body>
    </html>
  )
}

const js = String.raw

if (import.meta.hot) {
  if (typeof window !== 'undefined') {
    import.meta.hot.on('vite_custom:profiler:log', (data) => {
      console.log(JSON.parse(data))
    })
  }
}
